import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ContactInfo from "./ContactInfo";
import styles from "./modules/ContactUsBlock.module.scss";
import apiClient from "../../../apiClient";

function ContactUsBlock({
  showBottomImage = true,
  showBackgroundImage = true,
}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const recaptchaRef = useRef(null);

  // Wait for reCAPTCHA to initialize
  const waitForGrecaptcha = () => {
    const interval = setInterval(() => {
      if (window.grecaptcha && typeof window.grecaptcha.render === "function") {
        setIsScriptLoaded(true);
        clearInterval(interval);
      }
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      // if (!isScriptLoaded) {
      //   setStatusMessage("Failed to load reCAPTCHA. Please try again later.");
      // }
      // console.error("reCAPTCHA failed to load in time");
    }, 10000);
  };

  // Dynamically load the reCAPTCHA script
  useEffect(() => {
    const recaptchaScriptId = "recaptcha-script";

    if (!document.getElementById(recaptchaScriptId)) {
      const script = document.createElement("script");
      script.id = recaptchaScriptId;
      script.src = "https://www.google.com/recaptcha/api.js?cb=" + Date.now();
      script.async = true;
      script.defer = true;

      script.onload = waitForGrecaptcha;
      script.onerror = () => console.error("Failed to load reCAPTCHA script");

      document.body.appendChild(script);
    } else {
      waitForGrecaptcha();
    }
  }, []);

  // Reinitialize reCAPTCHA on navigation
  useEffect(() => {
    if (isScriptLoaded && recaptchaRef.current) {
      try {
        // Render the reCAPTCHA widget
        window.grecaptcha.render(recaptchaRef.current, {
          sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
          size: "normal",
        });
      } catch (error) {
        console.error("Error rendering reCAPTCHA:", error);
      }
    }
  }, [isScriptLoaded]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (
      !formData.email.trim() ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    )
      newErrors.email = "Invalid email.";
    if (!formData.subject.trim()) newErrors.subject = "Subject is required.";
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaResponse = document.querySelector(
      "#g-recaptcha-response"
    )?.value;

    if (!recaptchaResponse) {
      setStatusMessage("Please complete the reCAPTCHA.");
      return;
    }

    if (!validateForm()) {
      setStatusMessage("Please fix the errors and try again.");
      return;
    }

    try {
      const response = await apiClient.post("/contact", {
        ...formData,
        recaptcha: recaptchaResponse,
      });
      if (response.status === 200) {
        setStatusMessage("Message sent successfully!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        setStatusMessage("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatusMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div
      className={`${styles.firstBlock} ${
        showBackgroundImage ? styles.withBackground : ""
      }`}
    >
      <ContactInfo fromBusinessPage={!showBackgroundImage} />

      <div className={styles.contactForm}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            {errors.name && <p className={styles.errorText}>{errors.name}</p>}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <p className={styles.errorText}>{errors.email}</p>}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              placeholder="Enter subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
            {errors.subject && (
              <p className={styles.errorText}>{errors.subject}</p>
            )}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
            {errors.message && (
              <p className={styles.errorText}>{errors.message}</p>
            )}
          </div>

          <div ref={recaptchaRef}></div>

          <button className={styles.sendButton} type="submit">
            Send
          </button>
        </form>
        {statusMessage && (
          <p className={styles.statusMessage}>{statusMessage}</p>
        )}
      </div>

      {showBottomImage && (
        <div className={styles.bottomImage}>
          <img
            src="/images/ContactUs/horizontal-ressort.svg"
            alt="Contact Us"
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
}

ContactUsBlock.propTypes = {
  showBottomImage: PropTypes.bool,
  showBackgroundImage: PropTypes.bool,
};

export default ContactUsBlock;
