// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./components/layout/Layout";
import HomePage from "./components/organisms/HomePage/HomePage";
import OurMaterials from "./components/pages/OurMaterials/OurMaterials";
import AboutNAM from "./components/pages/AboutNAM/AboutNAM";
import Hospitality from "./components/pages/Business/Hospitality/Hospitality";
import Medical from "./components/pages/Business/Medical/Medical";
import SpecialCare from "./components/pages/Business/SpecialCare/SpecialCare";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import ProductsPage from "./components/pages/ProductsPage/ProductsPage";
import ProductPage from "./components/pages/ProductPage/ProductPage";
import ProductListingPage from "./components/pages/ProductListing/ProductListingPage";

// New imports for authentication
import Login from "./components/Auth/Login"; // Admin Login Component
import ProtectedRoute from "./components/Admin/ProtectedRoute"; // Protect admin routes
import AdminDashboard from "./components/Admin/Dashboard"; // Admin Dashboard component
import AddCategory from "./components/Admin/AddCategory";
import ManageProducts from "./components/Admin/ManageProducts";
import AddProduct from "./components/Admin/AddProduct";
import EditProduct from "./components/Admin/EditProduct";
import CategoriesList from "./components/Admin/CategoryList";
import ManageAnalyticsSnippets from "./components/Admin/ManageAnalyticsSnippets";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop /> {/* Ensure ScrollToTop is applied globally */}
        <Routes>
          {/* Routes that should use the Layout with Header and Footer */}
          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/our-materials"
            element={
              <Layout>
                <OurMaterials />
              </Layout>
            }
          />
          <Route
            path="/about-nam"
            element={
              <Layout>
                <AboutNAM />
              </Layout>
            }
          />
          <Route
            path="/for-the-hospitality-business"
            element={
              <Layout>
                <Hospitality />
              </Layout>
            }
          />
          <Route
            path="/for-medical-uses"
            element={
              <Layout>
                <Medical />
              </Layout>
            }
          />
          <Route
            path="/for-special-care"
            element={
              <Layout>
                <SpecialCare />
              </Layout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />
          <Route
            path="/our-products"
            element={
              <Layout>
                <ProductsPage />
              </Layout>
            }
          />
          <Route
            path="/products/:categoryName/:subCategoryName?/:productName"
            element={
              <Layout>
                <ProductPage />
              </Layout>
            }
          />{" "}
          {/* Add route to handle both category and subcategory */}
          <Route
            path="/product-category/:category/:subcategory"
            element={
              <Layout>
                <ProductListingPage />
              </Layout>
            }
          />
          {/* This route handles category without subcategory */}
          <Route
            path="/product-category/:category"
            element={
              <Layout>
                <ProductListingPage />
              </Layout>
            }
          />
          {/* This route handles Cleared filters */}
          <Route
            path="/product-category/"
            element={
              <Layout>
                <ProductListingPage />
              </Layout>
            }
          />
          {/* Admin login route - doesn't need Layout */}
          <Route path="/login" element={<Login />} />
          {/* Protected Admin Dashboard route */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminDashboard /> {/* Only accessible if logged in */}
              </ProtectedRoute>
            }
          />
          {/* Manage Products Route */}
          <Route
            path="/admin/products"
            element={
              <ProtectedRoute>
                <ManageProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/products/new"
            element={
              <ProtectedRoute>
                <AddProduct />
              </ProtectedRoute>
            }
          />
          {/* Add the Edit Product Route */}
          <Route
            path="/admin/products/edit/:productId"
            element={
              <ProtectedRoute>
                <EditProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/categories"
            element={
              <ProtectedRoute>
                <CategoriesList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/categories/new"
            element={
              <ProtectedRoute>
                <AddCategory />
              </ProtectedRoute>
            }
          />
          {/* Manage Analytics Snippets */}
          <Route
            path="/admin/analytics-snippets"
            element={
              <ProtectedRoute>
                <ManageAnalyticsSnippets /> {/* Add/Edit in popup */}
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
