import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./modules/Header.module.scss";
import Button from "../../atoms/Button/Button";
import apiClient from "../../../apiClient"; // Assuming apiClient is set up for API calls

function Header() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(""); // To store the search input
  const [searchResults, setSearchResults] = useState([]); // To store the search results
  const [showDropdown, setShowDropdown] = useState(false); // To control dropdown visibility

  // Handle search input change
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      // Make an API call to fetch products matching the search query
      try {
        const response = await apiClient.get(`/products/search?query=${query}`);

        // Ensure the API response is an array before using .map
        if (Array.isArray(response.data)) {
          setSearchResults(response.data);
        } else {
          setSearchResults([]); // Reset if response isn't an array
        }

        setShowDropdown(true); // Show the dropdown
      } catch (err) {
        console.error("Error fetching search results:", err);
        setSearchResults([]); // Reset if there's an error
      }
    } else {
      setSearchResults([]);
      setShowDropdown(false); // Hide the dropdown if query is too short
    }
  };

  // Handle redirect to product detail page
  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
    setShowDropdown(false); // Hide the dropdown after clicking
  };

  const handleRedirect = () => {
    navigate("/contact-us");
  };

  return (
    <header className={styles.header}>
      <div className={styles.logoNavContainer}>
        <Link to="/" className={styles.logo}>
          <img
            src="/images/header-logo.svg"
            alt="Website Logo"
            loading="lazy"
          />
        </Link>

        <nav className={styles.navTabs}>
          <div className={styles.navTab}>
            <Link to="/our-products">NAM Products</Link>
          </div>

          <div className={styles.navTab}>
            <a>For Your Business</a>
            <ul className={styles.dropdown}>
              <li>
                <a href="/for-the-hospitality-business">Hospitality</a>{" "}
                {/* Force reload */}
              </li>
              <li>
                <a href="/for-medical-uses">Medical</a>
              </li>
              <li>
                <a href="/for-special-care">Special Care</a>
              </li>
            </ul>
          </div>

          <div className={styles.navTab}>
            <Link to="/our-materials">Our Materials</Link>
          </div>
          <div className={styles.navTab}>
            <Link to="/about-nam">About NAM</Link>
          </div>
        </nav>
      </div>

      <div className={styles.searchContainer}>
        <div className={styles.searchIcon}>
          <img
            src="/images/magnifier.svg"
            className={styles.searchImage}
            alt="Search Icon"
            loading="lazy"
          />
        </div>
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchField}
          value={searchQuery}
          onChange={handleSearchChange} // Trigger search on input change
        />
        {/* Dropdown for search results */}
        {searchResults.length > 0 && (
          <div className={`${styles.searchDropdown} ${styles.active}`}>
            {searchResults.map((product) => (
              <div
                key={product.id}
                className={styles.searchResultItem}
                onClick={() => handleProductClick(product.id)}
              >
                <img src={product.primary_picture} alt={product.name} />
                <span>{product.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <Button
        text="Contact us!"
        className={styles.contactButton}
        onClick={handleRedirect}
      />
    </header>
  );
}

export default Header;
