// src/components/molecules/ProductCard/ProductCard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./modules/ProductCard.module.scss";

const ProductCard = ({ id, name, mainImage, width, category, subcategory }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (id) {
      const subCategorySegment = subcategory ? `/${subcategory}` : "";
      navigate(`/products/${category}${subCategorySegment}/${name}`, {
        state: { productId: id }, // Pass productId as state
      });
    }
  };

  return (
    <div
      className={styles.card}
      onClick={handleCardClick}
      style={width ? { width } : {}}
    >
      <img src={mainImage} alt={name} className={styles.image} />

      {/* Product Name */}
      <div className={styles.cardName}>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default ProductCard;
